import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { DatePicker, Form } from 'antd';
import { EditTwoTone } from '@ant-design/icons';
import TablePage from './Table';
import History from './History';
import Edit from './Edit';
import EditAll from './EditAll';
import calendar from '../../utils/lunar';
import festival from '../../utils/festival';
import firestore from '../../utils/firestore';

const monthFormat = 'YYYY/MM';

export default function Index() {
  const [showDate, setShowDate] = useState(moment());
  const [editAll, setEditAll] = useState(false);
  const [editId, setEditId] = useState();
  const [data, setData] = useState([]);
  const [nowFestival, setNowFestival] = useState([]);
  const [festivalData, setFestivalData] = useState([]);

  const year = showDate.get('year');
  const month = showDate.get('month');

  const endDay = parseInt(moment().set({ year, month }).endOf('month').format('D'));
  const dataSource = [];

  useEffect(() => {
    firestore
      .collection('accounting')
      .where('year', '==', year)
      .where('month', '==', month)
      .onSnapshot(accounting => {
        if (accounting.docs.length > 0) {
          const now = [];
          accounting.forEach(doc => {
            // console.log(doc.data());
            now.push({ id: doc.id, ...doc.data() });
          });
          setData(now);
        }
      });
  }, [showDate, month, year]);

  useEffect(() => {
    (async () => {
      const data = [];
      for (let i = 0; i < nowFestival.length; i++) {
        const element = nowFestival[i];
        const out = {
          festival: element
        };
        const accounting = await firestore
          .collection('accounting')
          .where('lunar', '==', element)
          .get();
        if (accounting.docs.length > 0) {
          accounting.forEach(doc => {
            const subData = doc.data();
            out[subData.year] = subData.income + subData.specialIncome;
          });
        }
        data.push(out);
      }
      setFestivalData(data);
    })();
  }, [nowFestival]);

  const save = async (data, notOpenNext = false) => {
    console.log(JSON.stringify(data));
    const collection = firestore.collection('accounting');
    const { id, ...saveData } = data;
    saveData.year = year;
    saveData.month = month;
    saveData.vendor = saveData.vendor || '';
    let doc;
    if (id) {
      doc = collection.doc(id);
    } else {
      doc = collection.doc();
    }
    await doc.set(saveData, { merge: true });
    if (!notOpenNext && endDay !== saveData.day) {
      setEditId(saveData.day + 1);
    } else {
      setEditId();
    }
  };

  const nowFestivalT = [];

  for (let i = 1; i <= endDay; i++) {
    const todayLunar = calendar.solar2lunar(year, month + 1, i);
    const week = new Date(year, month, i).getDay();
    let lunarStr = ``;
    const festivalStr = festival[`${todayLunar.lMonth}/${todayLunar.lDay}`];
    if (festivalStr) {
      lunarStr = festivalStr;
    } else if (
      todayLunar.lDay === 1 ||
      todayLunar.lDay === 15 ||
      todayLunar.lDay === 2 ||
      todayLunar.lDay === 16
    ) {
      lunarStr = `${todayLunar.lMonth}/${todayLunar.lDay}`;
    } else if (todayLunar.Term) {
      lunarStr = todayLunar.Term;
    }

    if (lunarStr !== '' && nowFestivalT.indexOf(lunarStr) === -1) {
      nowFestivalT.push(lunarStr);
    }

    const dbData = data.find(j => j.day === i);
    dataSource.push({
      day: i,
      lunar: lunarStr,
      week: week,
      ...dbData
    });
  }
  if (JSON.stringify(nowFestivalT) !== JSON.stringify(nowFestival)) {
    setNowFestival(nowFestivalT);
  }

  // const ss = () => {
  //   firestore
  //     .collection('accounting')
  //     .where('year', '==', 2020)
  //     .where('month', '==', 8)
  //     .get()
  //     .then(accounting => {
  //       if (accounting.docs.length > 0) {
  //         const now = [];
  //         accounting.forEach(doc => {
  //           const a = { id: doc.id, ...doc.data() };
  //           now.push(a);
  //           console.log(
  //             `${a.year} ${a.month} ${a.day} ${a.vendor} ${a.expenses} ${JSON.stringify(a)}`
  //           );
  //         });
  //         console.log(now);
  //       }
  //     });
  // };

  return (
    <div className="flex">
      <div style={{ margin: 8, maxWidth: 700 }}>
        {/* <div style={{ backgroundColor: '#a1a1a1', display: 'none' }} onClick={ss}>
          C
        </div> */}
        <Form.Item label="顯示" style={{ margin: 0, marginBottom: 4 }}>
          <DatePicker
            format={monthFormat}
            picker="month"
            value={showDate}
            onChange={time => {
              setData([]);
              setNowFestival([]);
              setFestivalData([]);
              setShowDate(time);
            }}
            allowClear={false}
            style={{ marginLeft: 4 }}
          />
          <EditTwoTone style={{ padding: '0 6px' }} onClick={() => setEditAll(true)} />
        </Form.Item>
        <TablePage onEdit={r => setEditId(r.day)} dataA={data} data={dataSource} />
        <Edit
          visible={!!editId}
          onClose={() => setEditId()}
          onSave={save}
          data={dataSource.find(i => i.day === editId)}
        />
        <EditAll
          visible={!!editAll}
          onClose={() => setEditAll(false)}
          onSave={save}
          data={dataSource}
        />
      </div>
      <div style={{ margin: 8 }}>
        <History data={festivalData} maxYear={moment().get('year')} />
      </div>
    </div>
  );
}
