import React from 'react';
import { Table } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import format_with_substring from '../../utils/number';

export default function (props = { data: [], onEdit: () => {} }) {
  const { data, onEdit } = props;
  const columns = [
    {
      title: '日期',
      dataIndex: 'day',
      key: 'day',
      align: 'center',
      render: (t, row) => <span className={`week_${row.week}`}>{`${t <= 9 ? '0' : ''}${t}`}</span>
    },
    {
      title: '農曆',
      dataIndex: 'lunar',
      key: 'lunar',
      align: 'center'
    },
    {
      title: '收入',
      dataIndex: 'income',
      key: 'income',
      align: 'right',
      render: t => format_with_substring(t)
    },
    {
      title: '特別項目',
      dataIndex: 'specialIncomeFrom',
      key: 'specialIncomeFrom',
      align: 'center'
    },
    {
      title: '特別收入',
      dataIndex: 'specialIncome',
      key: 'specialIncome',
      align: 'right',
      render: t => format_with_substring(t)
    },
    {
      title: '廠商',
      dataIndex: 'vendor',
      key: 'vendor',
      align: 'center'
    },
    {
      title: '支出',
      dataIndex: 'expenses',
      key: 'expenses',
      align: 'right',
      render: t => format_with_substring(t)
    },
    {
      dataIndex: 'action',
      align: 'center',
      fixed: 'right',
      width: 30,
      render: (_, data) => <EditOutlined onClick={onEdit.bind(null, data)} />
    }
  ];

  return (
    <Table
      dataSource={data}
      columns={columns}
      pagination={false}
      size="small"
      rowKey="day"
      scroll={{ x: 550 }}
      loading={data.length === 0}
      summary={pageData => {
        let totalIncome = 0;
        let totalSpecialIncome = 0;
        let totalExpenses = 0;

        pageData.forEach(({ income, specialIncome, expenses }) => {
          totalIncome += income || 0;
          totalSpecialIncome += specialIncome || 0;
          totalExpenses += expenses || 0;
        });
        const total = totalIncome + totalSpecialIncome - totalExpenses;
        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell>Total</Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell>
                <div style={{ textAlign: 'right' }}>{format_with_substring(totalIncome)}</div>
              </Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell>
                <div style={{ textAlign: 'right' }}>
                  {format_with_substring(totalSpecialIncome)}
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell>
                <div style={{ textAlign: 'right' }}>{format_with_substring(totalExpenses)}</div>
              </Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row>
              {Array(6)
                .fill(null)
                .map((_, index) => (
                  <Table.Summary.Cell key={index}></Table.Summary.Cell>
                ))}
              <Table.Summary.Cell>
                <div style={{ textAlign: 'right' }}>{format_with_substring(total)}</div>
              </Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        );
      }}
    />
  );
}
