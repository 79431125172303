import React, { useEffect } from 'react';
import './edit.css';
import { Modal, Form, InputNumber, Select, AutoComplete } from 'antd';

const { Option } = Select;
const VOption = [
  '小神堂',
  '源吉',
  '祥雲',
  '揚明',
  '伯明',
  '吉興',
  '祐恆',
  '香',
  '包-花袋',
  '雜支',
  '營業稅',
  '打火機'
];

const specialIncomeFromOptions = [
  { value: '親家' },
  { value: '阿賢' },
  { value: '亞田份' },
  { value: '金紙包' },
  { value: '姊' },
  { value: '邱太' },
  { value: '秀敏' },
  { value: '同安金紙' },
  { value: '鳳凰金紙' },
  { value: '春日金紙' },
  { value: '大業金紙' },
  { value: '南平金紙' },
  { value: '聖賢宮' },
  { value: '聖母宮' },
];

export default function (
  props = {
    visible: true,
    onClose: () => { },
    data: {}
  }
) {
  const { data } = props;
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      income: '',
      specialIncomeFrom: '',
      specialIncome: '',
      vendor: '',
      expenses: '',
      ...data
    });
  }, [data, form]);
  const handleOk = async e => {
    const a = await form.validateFields();
    // console.log(JSON.stringify(a));
    props.onSave({ ...props.data, ...a });
  };

  const handleCancel = e => {
    props.onClose();
  };

  return (
    <Modal
      title="編輯"
      visible={props.visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="存檔,Next"
      cancelText="取消"
    >
      <Form className="edit" form={form} scrollToFirstError>
        {props.data && (
          <>
            <Form.Item label="日期">
              {`${props.data.day && String(props.data.day).padStart(2, '0')} ${props.data.lunar ? `(${props.data.lunar})` : ''
                }`}
            </Form.Item>
            <Form.Item name="income" label="收入金額">
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item name="specialIncomeFrom" label="特別項目">
              <AutoComplete
                dropdownClassName="aa"
                options={specialIncomeFromOptions}
                filterOption={(inputValue, option) =>
                  option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Form.Item>
            <Form.Item name="specialIncome" label="特別收入">
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item name="vendor" label="收款廠商">
              <Select style={{ width: '100%' }} allowClear>
                {VOption.map(i => (
                  <Option key={i} value={i}>
                    {i}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="expenses" label="支出金額">
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
}
