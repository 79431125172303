import React, { useState } from 'react';
import { Card, Button } from 'antd';
import { useAuth } from '../utils/auth';
import Icon from '@ant-design/icons';

const LoginSvg = () => (
  <svg viewBox="0 0 32 32" width="20px" height="20px">
    <g>
      <path d="M11.71,15.29l-4-4a1,1,0,0,0-1.09-.21A1,1,0,0,0,6,12v1H3a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H6v1a1,1,0,0,0,.62.92A.84.84,0,0,0,7,21a1,1,0,0,0,.71-.29l4-4A1,1,0,0,0,11.71,15.29Z" />
      <path d="M20,29.52a2,2,0,0,1-1.12-.34A2,2,0,0,1,18,27.52v-23a2,2,0,0,1,2.74-1.86l8,3.2A2,2,0,0,1,30,7.68V24.32a2,2,0,0,1-1.26,1.86l-8,3.2A1.91,1.91,0,0,1,20,29.52Zm0-25V27.53l8-3.21V7.68Z" />
      <path d="M22,19a1,1,0,0,1-1-1V14a1,1,0,0,1,2,0v4A1,1,0,0,1,22,19Z" />
      <path d="M19,5H15a2,2,0,0,0-2,2V26a2,2,0,0,0,2,2h4a1,1,0,0,0,1-1V6A1,1,0,0,0,19,5Z" />
      <path d="M20,24c6.83-.25,8-5,8-6.67l1,7a1,1,0,0,1-.63.93l-8.53,3.47-.23-3.5Z" />
    </g>
  </svg>
);

const LoginIcon = props => <Icon component={LoginSvg} {...props} />;

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}
    >
      <Card className="login" style={{ width: 236, height: 82 }} loading={loading}>
        <Button
          onClick={() => {
            setLoading(true);
            auth.signin();
          }}
        >
          <LoginIcon /> Sign In with Google
        </Button>
      </Card>
    </div>
  );
};
