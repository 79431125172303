import React, { useContext, createContext, useState } from 'react';
import firebase from 'firebase/app';
import Login from '../pages/Login';
import 'firebase/auth';
const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export function useAuth() {
  return useContext(authContext);
}

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [email, setMail] = useState(null);
  firebase.auth().onAuthStateChanged(
    function (user) {
      // if (user && (user.email.indexOf('anson') > -1 || user.email.indexOf('vist') > -1)) {
      if (user) {
        setUser(user.displayName);
        setMail(user.email);
      }
      // } else {
      //   setUser('');
      // }
    },
    function (error) {
      console.log(error);
      alert('Unable to log in: ' + error);
    }
  );

  const signin = cb => {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    const auth = firebase.auth();
    // auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
    auth
      .signInWithPopup(provider)
      .then(result => {
        // const credential = result.credential;
        // const token = credential.accessToken;
        const user = result.user;
        // if (user.email.indexOf('anson') === -1 && user.email.indexOf('vist') === -1) {
        //   //   firebase.app().auth().signOut();
        // } else {
        if (user) {
          setUser(user.displayName);
          setMail(user.email);
          cb && cb(user);
        }
      })
      .catch(error => {
        // Handle Errors here.
        // var errorCode = error.code;
        // var errorMessage = error.message;
        // The email of the user's account used.
        // var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        // var credential = error.credential;
        // ...
        console.log(error);
      });
  };

  const signout = cb => {
    firebase
      .app()
      .auth()
      .signOut()
      .then(() => {
        cb();
      });
  };

  return {
    user,
    email,
    signin,
    signout
  };
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  // if (auth.user === null) {
  //   return <div />;
  // }
  return auth.user && auth.user !== '' ? children : <Login />;
}
