import React, { useEffect, useState } from 'react';
import './edit.css';
import { Modal, Input, Radio } from 'antd';

const { TextArea } = Input;

const type = [
  { label: '收入', value: 'income' },
  { label: '特別收入', value: 'specialIncome' }
];

export default function (
  props = {
    visible: true,
    onClose: () => { },
    data: []
  }
) {
  const { data } = props;
  const [textArea, setTextArea] = useState('');
  const [keyType, setKeyType] = useState('income');
  const [rowNum, setRowNum] = useState(5);

  useEffect(() => {
    setTextArea(data.map(i => `${i[keyType] || ''}`).join('\n'));
    setRowNum(Math.min(data.length, 31));
  }, [data, keyType]);

  const handleOk = async () => {
    const out = [...data];
    const arr = textArea.split('\n');
    const num = Math.min(out.length, arr.length);
    for (let i = 0; i < num; i++) {
      const element = arr[i].trim();
      if (element) {
        out[i][keyType] = parseInt(element);
        props.onSave({ ...out[i] }, true);
      }
    }
    props.onClose();
  };

  const handleCancel = e => {
    props.onClose();
  };

  const handleSizeChange = e => {
    setKeyType(e.target.value);
  };

  return (
    <Modal
      title="編輯月份"
      visible={props.visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="存檔"
      cancelText="取消"
    >
      <div style={{ textAlign: 'center', margin: 4 }}>
        <Radio.Group value={keyType} onChange={handleSizeChange}>
          {type.map(i => (
            <Radio.Button key={i.value} value={i.value}>
              {i.label}
            </Radio.Button>
          ))}
        </Radio.Group>
      </div>
      <div className="textAreaWithLines">
        <div className="lineObj">
          {Array.apply(null, Array(rowNum)).map((val, idx) => (
            <div key={idx}>{idx + 1}</div>
          ))}
        </div>
        <TextArea
          className="codeTextarea"
          value={textArea}
          onChange={e => {
            let { value } = e.target
            // if (e.target.value && e.target.value.match(/\r?\n|\r/g)) {
            const num = e.target.value.match(/\r?\n|\r/g).length + 1;
            //   if (num > 31) {
            //     value = value.replace(/\r?\n$|\r$/g, '');
            //   }
            //   setRowNum(Math.min(num, 31));
            // }
            setRowNum(num);
            setTextArea(value);
          }}
          rows={rowNum}
        />
      </div>
    </Modal>
  );
}
