export default {
  '1/1': '新年',
  '1/9': '天公生',
  '1/15': '元宵節',
  '1/17': '安斗',
  '2/2': '頭牙',
  '3/3': '上巳節',
  '3/23': '迎媽祖',
  '4/8': '浴佛節',
  '5/5': '端午節',
  '7/7': '七夕',
  '7/15': '中元節',
  '7/18': '普渡',
  '8/15': '中秋節',
  '9/9': '重陽節',
  '10/15': '下元節',
  '12/7': '謝斗',
  '12/16': '尾牙',
  '12/24': '送神'
};
