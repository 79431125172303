import React from 'react';
import { Table } from 'antd';
import format_with_substring from '../../utils/number';

export default function (props = { data: [], maxYear: 2017 }) {
  const { data, maxYear } = props;
  const columns = [
    {
      title: '節日',
      dataIndex: 'festival',
      align: 'center'
    }
  ];
  for (let i = maxYear - 1; i >= 2010; i--) {
    // for (let i = 2010; i <= maxYear; i++) {
    columns.push({
      title: i,
      dataIndex: i,
      align: 'right',
      render: t => format_with_substring(t)
    });
  }

  return (
    <Table
      dataSource={data}
      columns={columns}
      pagination={false}
      size="small"
      rowKey="festival"
      scroll={{ x: 500 }}
    />
  );
}
