import React from 'react';
import { Table } from 'antd';
import format_with_substring from '../../utils/number';

export default function (props = { data: [] }) {
  const { data } = props;
  // console.log(JSON.stringify(data));
  const columns = [
    {
      title: '月份',
      dataIndex: 'title',
      align: 'center'
    },
    {
      title: '01',
      dataIndex: '1',
      align: 'right',

      render: t => (t ? format_with_substring(t) : undefined)
    },
    {
      title: '02',
      dataIndex: '2',
      align: 'right',

      render: t => (t ? format_with_substring(t) : undefined)
    },
    {
      title: '03',
      dataIndex: '3',
      align: 'right',

      render: t => (t ? format_with_substring(t) : undefined)
    },
    {
      title: '04',
      dataIndex: '4',
      align: 'right',

      render: t => (t ? format_with_substring(t) : undefined)
    },
    {
      title: '05',
      dataIndex: '5',
      align: 'right',

      render: t => (t ? format_with_substring(t) : undefined)
    },
    {
      title: '06',
      dataIndex: '6',
      align: 'right',

      render: t => (t ? format_with_substring(t) : undefined)
    },
    {
      title: '07',
      dataIndex: '7',
      align: 'right',

      render: t => (t ? format_with_substring(t) : undefined)
    },
    {
      title: '08',
      dataIndex: '8',
      align: 'right',

      render: t => (t ? format_with_substring(t) : undefined)
    },
    {
      title: '09',
      dataIndex: '9',
      align: 'right',

      render: t => (t ? format_with_substring(t) : undefined)
    },
    {
      title: '10',
      dataIndex: '10',
      align: 'right',

      render: t => (t ? format_with_substring(t) : undefined)
    },
    {
      title: '11',
      dataIndex: '11',
      align: 'right',

      render: t => (t ? format_with_substring(t) : undefined)
    },
    {
      title: '12',
      dataIndex: '12',
      align: 'right',

      render: t => (t ? format_with_substring(t) : undefined)
    },
    {
      title: '總計',
      dataIndex: 'total',
      align: 'right',

      render: t => (t ? format_with_substring(t) : undefined)
    }
  ];
  const vertical = [];
  const showData = [];
  for (let i = 0; i < data.length; i++) {
    const element = data[i];
    const month = element.month + 1;
    // 計算支出
    let index = vertical.indexOf(element.specialIncomeFrom);
    if (index === -1) {
      index = vertical.length;
      vertical.push(element.specialIncomeFrom);
    }
    if (!showData[index]) {
      showData[index] = {};
    }
    if (showData[index][month]) {
      showData[index][month] += element.specialIncome || 0;
    } else {
      showData[index][month] = element.specialIncome || 0;
    }
  }
  for (let i = 0; i < showData.length; i++) {
    if (!showData[i]) {
      showData[i] = {};
    }
    showData[i].title = vertical[i];
    showData[i].key = vertical[i] || i;
    for (let j = 0; j < 12; j++) {
      if (showData[i][`${j + 1}`]) {
        if (!showData[i]['total']) {
          showData[i]['total'] = 0;
        }
        showData[i]['total'] += showData[i][`${j + 1}`] || 0;
      }
    }
  }
  // console.log(JSON.stringify(showData));

  return (
    <Table
      dataSource={showData}
      columns={columns}
      pagination={false}
      size="small"
      rowKey="key"
      scroll={{ x: '100%' }}
      loading={data.length === 0}
      summary={pageData => {
        let total = [];
        pageData.forEach((data, index) => {
          for (let i = 1; i <= 12; i++) {
            if (data[`${i}`]) {
              if (!total[i]) {
                total[i] = 0;
              }
              total[i] += data[`${i}`] || 0;
            }
          }
        });
        return (
          <>
            <Table.Summary.Row>
              {Array(14)
                .fill(null)
                .map((_, index) => (
                  <Table.Summary.Cell key={index}>
                  </Table.Summary.Cell>
                ))}
            </Table.Summary.Row>
            <Table.Summary.Row>
              <Table.Summary.Cell>
                <div style={{ textAlign: 'center' }}>
                  總計
                </div>
              </Table.Summary.Cell>
              {Array(12)
                .fill(null)
                .map((_, index) => (
                  <Table.Summary.Cell key={index}>
                    <div style={{ textAlign: 'right' }}>
                      {format_with_substring(total[index + 1])}
                    </div>
                  </Table.Summary.Cell>
                ))}
              <Table.Summary.Cell>
                <div style={{ textAlign: 'right' }}>
                  {total.length > 0 ? format_with_substring(total.reduce((a, b) => a + b)) : ''}
                </div>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        );
      }}
    />
  );
}
