export default {
  apiKey: 'AIzaSyDJlid4Loqv-5d9HGgKqPpjboidx-6rhgE',
  authDomain: 'money-501b9.firebaseapp.com',
  databaseURL: 'https://money-501b9.firebaseio.com',
  projectId: 'money-501b9',
  storageBucket: 'money-501b9.appspot.com',
  messagingSenderId: '276935159296',
  appId: '1:276935159296:web:f3f05e015a701380ef32e5'
};
// export default {
//   apiKey: "AIzaSyDezHEFr88WFfu6oPNdqUgYqZ3tOQ_xUyk",
//   authDomain: "test-a0108.firebaseapp.com",
//   databaseURL: "https://test-a0108.firebaseio.com",
//   projectId: "test-a0108",
//   storageBucket: "test-a0108.appspot.com",
//   messagingSenderId: "822526928703",
//   appId: "1:822526928703:web:3b862f040f5a0b4a9dd58a",
// };
