import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { DatePicker, Form } from 'antd';
import TablePage from './Table';
import TableComePage from './TableCome';
import firestore from '../../utils/firestore';
import './index.css';

const yearFormat = 'YYYY';

export default function Index() {
  const [showDate, setShowDate] = useState(moment());
  const [data, setData] = useState([]);
  const year = showDate.get('year');

  useEffect(() => {
    firestore
      .collection('accounting')
      .where('year', '==', year)
      .get()
      .then(accounting => {
        if (accounting.docs.length > 0) {
          const now = [];
          accounting.forEach(doc => {
            now.push({ id: doc.id, ...doc.data() });
          });
          setData(now);
        }
      });
  }, [showDate, year]);

  return (
    <div style={{ margin: 8 }}>
      <Form.Item label="顯示" style={{ margin: 0 }}>
        <DatePicker
          format={yearFormat}
          picker="year"
          value={showDate}
          onChange={time => {
            setData([]);
            setShowDate(time);
          }}
          allowClear={false}
          style={{ marginLeft: 4 }}
        />
      </Form.Item>
      <div style={{ margin: '10px 0' }}>
        <TablePage data={data} />
      </div>
      <div style={{ margin: '10px 0' }}>
        <TableComePage data={data} />
      </div>
    </div>
  );
}
