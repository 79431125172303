import React, { useState } from 'react';
import moment from 'moment';
import 'moment/dist/locale/zh-tw'
import zhTw from 'antd/lib/locale/zh_TW'
import { Layout, Button, Radio, ConfigProvider } from 'antd';
import Icon from '@ant-design/icons';
import { ProvideAuth, PrivateRoute } from '../utils/auth';
import KeyinIndex from '../pages/keyin/Index';
import BoardIndex from '../pages/board/Index';
import firebase from 'firebase/app';
import 'firebase/auth';
import './App.css';

moment.locale('zh-tw');

const LogoutSvg = () => (
  <svg viewBox="0 0 32 32" width="20px" height="20px">
    <title />
    <g>
      <path d="M11,13H8V12a1,1,0,0,0-.62-.92,1,1,0,0,0-1.09.21l-4,4a1,1,0,0,0,0,1.42l4,4A1,1,0,0,0,7,21a.84.84,0,0,0,.38-.08A1,1,0,0,0,8,20V19h3a1,1,0,0,0,1-1V14A1,1,0,0,0,11,13Z" />
      <path d="M20,29.52a2,2,0,0,1-1.12-.34A2,2,0,0,1,18,27.52v-23a2,2,0,0,1,2.74-1.86l8,3.2A2,2,0,0,1,30,7.68V24.32a2,2,0,0,1-1.26,1.86l-8,3.2A1.91,1.91,0,0,1,20,29.52Zm0-25V27.53l8-3.21V7.68Z" />
      <path d="M22,19a1,1,0,0,1-1-1V14a1,1,0,0,1,2,0v4A1,1,0,0,1,22,19Z" />
      <path d="M19,5H15a2,2,0,0,0-2,2V26a2,2,0,0,0,2,2h4a1,1,0,0,0,1-1V6A1,1,0,0,0,19,5Z" />
      <path d="M20,24c6.83-.25,8-5,8-6.67l1,7a1,1,0,0,1-.63.93l-8.53,3.47-.23-3.5Z" />
    </g>
  </svg>
);

const LogoutIcon = props => <Icon component={LogoutSvg} {...props} />;

const { Header, Footer, Content } = Layout;

function ContentApp() {
  const [nowType, setNowType] = useState('keyin');
  const select = {
    keyin: <KeyinIndex />,
    board: <BoardIndex />
  };
  const nowShow = select[nowType];
  return (
    <Layout>
      <Header>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '100%'
          }}
        >
          <Radio.Group
            options={[
              { label: '輸入', value: 'keyin' },
              { label: '年度', value: 'board' }
            ]}
            onChange={e => setNowType(e.target.value)}
            value={nowType}
            optionType="button"
            style={{ lineHeight: 'initial' }}
          />
          <Button
            className="logout"
            onClick={() => {
              firebase.app().auth().signOut();
            }}
          >
            <LogoutIcon />
          </Button>
        </div>
      </Header>
      <Content>{nowShow}</Content>
      <Footer></Footer>
    </Layout>
  );
}

function App() {
  return (
    <ConfigProvider locale={zhTw}>
      <ProvideAuth>
        <PrivateRoute>
          <ContentApp />
        </PrivateRoute>
      </ProvideAuth>
    </ConfigProvider>
  );
}

export default App;
